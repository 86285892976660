import React from 'react';

const Offgrid = () => {
    return (
        <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
            <div className="container feature px-lg-0">
                <div className="row g-0 mx-lg-0">
                    <div className="col-lg-6 pe-lg-0 wow fadeIn" data-wow-delay="0.5s">
                        <div className="position-relative h-100">
                            <img className="position-absolute img-fluid w-100 h-100" src="img/offgrid.jpg" style={{ objectFit: 'fill' }} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 feature-text py-5 wow fadeIn" data-wow-delay="0.1s">
                        <div className="p-lg-5 ps-lg-0">
                            <h1 className="mb-4">Off-Grid Solar System</h1>
                            <p className="mb-4 pb-2">Specially designed for remote residences, An <strong>Off-Grid Solar System</strong> is a complete power bank that is independent of the utility grid for functioning. This type of Solar System is also known as battery-based system and stand-alone system.</p>
                            <div className="row g-4">
                                <div className="col-6">
                                    <div className="d-flex align-items-center">
                                        <div className="btn-lg-square bg-primary rounded-circle">
                                            <i className="fa fa-check text-white"></i>
                                        </div>
                                        <div className="ms-2">
                                            <p className="mb-0">Quality</p>
                                            <h6 className="mb-0">Services</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="d-flex align-items-center">
                                        <div className="btn-lg-square bg-primary rounded-circle">
                                            <i className="fa fa-user-check text-white"></i>
                                        </div>
                                        <div className="ms-2">
                                            <p className="mb-0">Expert</p>
                                            <h6 className="mb-0">Workers</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="d-flex align-items-center">
                                        <div className="btn-lg-square bg-primary rounded-circle">
                                            <i className="fa fa-drafting-compass text-white"></i>
                                        </div>
                                        <div className="ms-2">
                                            <p className="mb-0">Free</p>
                                            <h6 className="mb-0">Consultation</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="d-flex align-items-center">
                                        <div className="btn-lg-square bg-primary rounded-circle">
                                            <i className="fa fa-headphones text-white"></i>
                                        </div>
                                        <div className="ms-2">
                                            <p className="mb-0">Customer</p>
                                            <h6 className="mb-0">Support</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Offgrid;