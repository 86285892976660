import React from 'react';

const BenefitsCommercial = () => {
    return (
        <div className = "benefits">
        <div className="container">

        <div className="section-title" data-aos="fade-up">
          <h2>Benefits of Using Commercial Solar Solutions</h2>
        </div>
  
        <div className="row">
          <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
              <div className="icon"><i className='cost'></i></div>
              <h4 className="title">A Lower cost of
electricity from solar</h4>
            </div>
          </div>
  
          <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
              <div className="icon"><i className="refund"></i></div>
              <h4 className="title">Enjoy a payback
of just 5-6 years</h4>
            </div>
          </div>
  
          <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
              <div className="icon"><i className="solar"></i></div>
              <h4 className="title">Sell your surplus energy
back to grid</h4>
            </div>
          </div>
  
          <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
              <div className="icon"><i className="battery"></i></div>
              <h4 className="title">Integrate your rooftop solar
system with battery backup
for more reliable power</h4>
            </div>
          </div>
  
          <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
              <div className="icon"><i className="co2"></i></div>
              <h4 className="title">Reduce your carbon
footprint and make a positive
environmental impact</h4>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
              <div className="icon"><i className="fix"></i></div>
              <h4 className="title">Have peace of mind
with Our unique after-sales
service packages</h4>
            </div>
          </div>
  
        </div>
  
      </div>
      </div>
    );
}

export default BenefitsCommercial;