import React, { useEffect } from 'react';
import { useState } from 'react';
import {load} from './firebase';




function Admin() {
    const [data, setData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mount
    getData();
}, []);

async function getData(){
    let a = await load();
    setData(a)
    console.log(a)
}

  return (
    <div style={{overflow:'auto'}}>
            <table className="table table-bordered table-hover ms-5 me-5" style={{ maxWidth:'100%'}}>
            <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Name</th>
      <th scope="col">Email</th>
      <th scope="col">Mobile</th>
      <th scope="col">service</th>
      <th scope="col">Address</th>
      <th scope="col">Unit</th>
      <th scope="col">Note</th>
    </tr>
  </thead>
  <tbody>
  {console.log(data)}
  {data.map(((item, i) =>
  <tr>
      <th key={item.id} scope="row">{i + 1}</th>
      <td>{item.name}</td>
      <td>{item.email}</td>
      <td>{item.mobile}</td>
      <td>{item.service}</td>
      <td>{item.location}</td>
      <td>{item.unit}</td>
      <td >{item.note}</td>
    </tr>
  ))}
    
  </tbody>
</table>
    </div>
  );
}

export default Admin;