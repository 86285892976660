import React from 'react';

const BenefitsGovernment = () => {
    return (
      <div className="container-xxl py-5" id="products-section">
      <div className="container">
          <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '600px' }}>
              <h1 className="mb-4">Why Choose Yogreen</h1>
          </div>
          <div className="row g-4 portfolio-container wow fadeInUp" data-wow-delay="0.5s">
              <div className="col-lg-4 col-md-6 portfolio-item">
                      <img className="img-fluid" style={{height:'150px', width:'400px'}} src="img/MNRE.svg" alt="" />
                  <div className="pt-3">
                      <h5 className="lh-base text-center">MNRE registered</h5>
                  </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item">
                      <img className="img-fluid" style={{height:'150px', width:'400px'}} src="img/CREDA.png" alt="" />
                  <div className="pt-3">
                      <h5 className="lh-base text-center">CREDA registered</h5>
                  </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item">
                      <img className="img-fluid" style={{height:'150px', width:'400px'}} src="img/CSPDCL.png" alt="" />
                  <div className="pt-3">
                      <h5 className="lh-base text-center">CSPDCL registered</h5>
                  </div>
              </div>
          </div>
      </div>
  </div>
    );
}

export default BenefitsGovernment;