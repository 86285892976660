/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom'; 

const Service = () => {

    return (
        <div className="container-xxl py-5" id="services-section">
            <div className="container">
                <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '600px' }}>
                    <h6 className="text-primary">Our Services</h6>
                    <h1 className="mb-4">All Kind of Solar Solutions</h1>
                </div>
                <div className="row g-4">
                    <div className="col-md-6 col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="service-item rounded overflow-hidden">
                            <img className="img-fluid" src="img/residential.jpg" alt="" />
                            <div className="position-relative p-4 pt-0">
                                <div className="service-icon">
                                    <i className="fa fa-solar-panel fa-3x"></i>
                                </div>
                                <h4 className="mb-3">Residential Solar Solutions</h4>
                                <p>We provide Rooftop solar solutions (Ongrid/Offgrid/Hybrid), Solar Heater, Solar Pumps and Outdoor Lighting Solutions.</p>
                                <Link to="/Residential" className="small fw-medium">Read More<i className="fa fa-arrow-right ms-2"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="service-item rounded overflow-hidden">
                        <img className="img-fluid" src="img/commercial.jpg" alt="" />
                        <div className="position-relative p-4 pt-0">
                            <div className="service-icon">
                                <i className="fa fa-solar-panel fa-3x"></i>
                            </div>
                            <h4 className="mb-3">Commercial Solar Solutions</h4>
                            <p>We provide large scale solar solutions(10KWs-MWs).</p>
                            <Link to="/Commercial" className="small fw-medium">Read More<i className="fa fa-arrow-right ms-2"></i></Link>
                        </div>
                    </div>
                </div>
                <div className="row g-4">
                    <div className="col-md-6 col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="service-item rounded overflow-hidden">
                            <img className="img-fluid" src="img/Industrial.jpg" alt="" />
                            <div className="position-relative p-4 pt-0">
                                <div className="service-icon">
                                    <i className="fa fa-solar-panel fa-3x"></i>
                                </div>
                                <h4 className="mb-3">Industrial Solar Solutions</h4>
                                <p>We provide Industrial solar solutions (Captive/Power Plant).</p>
                                <Link to="/Commercial" className="small fw-medium">Read More<i className="fa fa-arrow-right ms-2"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="service-item rounded overflow-hidden">
                        <img className="img-fluid" src="img/government.jpg" alt="" />
                        <div className="position-relative p-4 pt-0">
                            <div className="service-icon">
                                <i className="fa fa-solar-panel fa-3x"></i>
                            </div>
                            <h4 className="mb-3">Government Solar Projects</h4>
                            <p>We provide solutions for government projects like Public Lighting(high mast/street lights) and MNRE schemes(KUSUM).</p>
                            <Link to="/Government" className="small fw-medium">Read More<i className="fa fa-arrow-right ms-2"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    );
}

export default Service;