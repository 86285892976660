/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
    const navigate = useNavigate();
    const [activeLink, setActiveLink] = useState('home');

    const handleNavLinkClick = (sectionId, event) => {
        event.preventDefault();
        setActiveLink(sectionId);
        navigate('/'); // Redirect to the home page
        setTimeout(() => {
            const section = document.getElementById(sectionId);
            section.scrollIntoView({ behavior: 'smooth' });
        }, 100); // Timeout to ensure redirect completes before scrolling
    };

    return (
        <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0">
            <a href="/" className="navbar-brand d-flex align-items-center border-end px-4 px-lg-5">
                <h2 className="m-0 text-primary">YOGREEN INDUSTRIES <br /> <p style={{fontSize: "20px", textAlign:"center"}}> Solarizing India </p></h2>
            </a>
            <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav ms-auto p-4 p-lg-0">
                    <a href="/" className={`nav-item nav-link ${activeLink === 'home' ? 'active' : ''}`}>Home</a>
                    <a href="#about-section" onClick={(e) => handleNavLinkClick('about-section', e)} className={`nav-item nav-link ${activeLink === 'about-section' ? 'active' : ''}`}>About</a>
                    <a href="#services-section" onClick={(e) => handleNavLinkClick('services-section', e)} className={`nav-item nav-link ${activeLink === 'services-section' ? 'active' : ''}`}>Services</a>
                    <a href="#products-section" onClick={(e) => handleNavLinkClick('products-section', e)} className={`nav-item nav-link ${activeLink === 'products-section' ? 'active' : ''}`}>Products</a>
                    <a href="#faq-section" onClick={(e) => handleNavLinkClick('faq-section', e)} className={`nav-item nav-link ${activeLink === 'faq-section' ? 'active' : ''}`}>F.A.Q</a>
                    <a 
                    href="https://solar-repository.vercel.app/" className={`nav-item nav-link ${activeLink === 'faq-section' ? 'active' : ''}`} target="_blank" rel="noopener noreferrer">Calculate Your Usage</a>
{/*                     <div className="nav-item dropdown">
                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                        <div className="dropdown-menu bg-light m-0">
                            <a href="feature.html" className="dropdown-item">Feature</a>
                            <a href="quote.html" className="dropdown-item">Free Quote</a>
                            <a href="team.html" className="dropdown-item">Our Team</a>
                            <a href="testimonial.html" className="dropdown-item">Testimonial</a>
                            <a href="404.html" className="dropdown-item">404 Page</a>
                        </div>
                    </div> */}
                    <a href="#contact-section" onClick={(e) => handleNavLinkClick('contact-section', e)} className="btn btn-primary rounded-0 py-4 px-lg-5 d-none d-lg-block">Get A Quote<i className="fa fa-arrow-right ms-3"></i></a>
                </div>
                {/* <a href="" className="btn btn-primary rounded-0 py-4 px-lg-5 d-none d-lg-block">Get A Quote<i className="fa fa-arrow-right ms-3"></i></a> */}
            </div>
        </nav>
    );
}

export default Navbar;