import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Carouselhome = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };

    return (
        <div className="container-fluid p-0 pb-5 wow fadeIn" data-wow-delay="0.1s" style={{ overflow: 'hidden' }}>
            <Slider {...settings} className="header-carousel position-relative">
                <div className="owl-carousel-item position-relative">
                    <img className="img-fluid" src="img/carousel-1.jpg" alt="" />
                    <div className="owl-carousel-inner">
                        <div className="container">
                            <div className="row justify-content-start">
                                <div className="col-10 col-lg-8">
                                    <h1 className="display-2 text-white animated slideInDown">Go Green With YoGreen</h1>
                                    <p className="fs-4 fw-medium text-white mb-4 pb-3">Residential , Commercial and Industrial Solar Services Provider.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="owl-carousel-item position-relative">
                    <img className="img-fluid" src="img/carousel-2.jpg" alt="" />
                    <div className="owl-carousel-inner">
                        <div className="container">
                            <div className="row justify-content-start">
                                <div className="col-10 col-lg-8">
                                    <h1 className="display-2 text-white animated slideInDown">Solarizing India<br /> No. 1 EPC Company</h1>
                                    <p className="fs-4 fw-medium text-white mb-4 pb-3">All Kind Of Solar Work</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    );
}

export default Carouselhome;