/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom'; 

const About = () => {
    return (
        <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0" id="about-section">
            <div className="container about px-lg-0">
                <div className="row g-0 mx-lg-0">
                    <div className="col-lg-6 ps-lg-0 wow fadeIn" data-wow-delay="0.1s" style={{ minHeight: "400px" }}>
                        <div className="position-relative h-100">
                            <img className="position-absolute img-fluid w-100 h-100" src="img/about.jpg" style={{ objectFit: "cover" }} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 about-text py-5 wow fadeIn" data-wow-delay="0.5s">
                        <div className="p-lg-5 pe-lg-0">
                            <h6 className="text-primary">About Us</h6>
                            <h1 className="mb-4">Residential , Commercial and Industrial Solar Services Provider.</h1>
                            <p>Since Our Beginning, YoGreen Industries has been committed to combining energy efficiency with solar power, caring for the planet and providing superior customer service. We have been names the #1 Fastest growing Business in Chhattisgarh, the #1 residential solar installer in bilaspur and the 356th fastest growing company in the nation on the inc. 500 list. Our Goal is to provide you with an easy, affordable way to go solar and start saving money.</p>
                            <i className="fa fa-check-circle text-primary me-3" style={{float: 'left', lineHeight: 1.7}}></i><p><strong>Expertise:</strong> Highly skilled professionals delivering top-quality solar solutions.</p>
                            <i className="fa fa-check-circle text-primary me-3" style={{float: 'left', lineHeight: 1.7}}></i><p><strong>Quality Products:</strong> Partnering with leading manufacturers for the highest quality solar panels and equipment.</p>
                            <i className="fa fa-check-circle text-primary me-3" style={{float: 'left', lineHeight: 1.7}}></i><p><strong>Customer Service:</strong> Prioritizing customer satisfaction from consultation to installation and beyond.</p>
                            <i className="fa fa-check-circle text-primary me-3" style={{float: 'left', lineHeight: 1.7}}></i><p><strong>Sustainability:</strong> Passionate about environmental protection and reducing carbon footprint through solar energy.</p>
                            <Link to="/Aboutus" className="btn btn-primary rounded-pill py-3 px-5 mt-3">Explore More</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
