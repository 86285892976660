import React, { useEffect } from 'react';
import Carouselhome from './Components/Carouselhome';
import BenefitsGovernment from './Components/Benefitsgovernment';
import Governmentschemes from './Components/Governmentschemes';


function Government() {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
}, []);

  return (
    <div>
            <Carouselhome />
            <BenefitsGovernment />
            <Governmentschemes />
    </div>
  );
}

export default Government;