import React, { useEffect } from 'react';
import Carouselcommercial from './Components/Carouselcommercial';
import BenefitsCommercial from './Components/BenefitsCommercial';


function Commercial() {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
}, []);

  return (
    <div>
            <Carouselcommercial />
            <BenefitsCommercial />
    </div>
  );
}

export default Commercial;