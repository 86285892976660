import { initializeApp } from "firebase/app";
import { addDoc, collection, getFirestore, serverTimestamp, query, orderBy, getDocs } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBDovX_Z9DO5aIpf_GUc79VWLHMgzwust4",
    authDomain: "solar-yogreen.firebaseapp.com",
    projectId: "solar-yogreen",
    storageBucket: "solar-yogreen.appspot.com",
    messagingSenderId: "82356613785",
    appId: "1:82356613785:web:4afd456f93ae31adb87e08"
  };

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);
const auth = getAuth(firebaseApp);


export async function addData(name, email, mobile, service, location, unit, note){
    await addDoc(collection(db,'query'),{
        name:name,
        email:email,
        mobile:mobile,
        service:service,
        location:location,
        unit:unit,
        note:note,
        time: serverTimestamp()

    });


}

export async function load (){
    
    const loadQuery = query(collection(db, "query"), orderBy('time', 'desc'));

    const querySnapshot = await getDocs(loadQuery);
    
    let services = [];

    querySnapshot.docs.forEach((doc)=>{
        let tempDoc = doc.data();
        tempDoc.id = doc.id;
        services.push(tempDoc);
    });

    return services
}

export async function signIn(email, password){
    const result = await signInWithEmailAndPassword(auth, email, password );
}