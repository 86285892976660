import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Carouselresidential = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };

    return (
        <div className="container-fluid p-0 pb-5 wow fadeIn" data-wow-delay="0.1s" style={{ overflow: 'hidden' }}>
            <Slider {...settings} className="header-carousel position-relative">
                <div className="owl-carousel-item position-relative">
                    <img className="img-fluid" src="img/residentialheater.jpg" alt="" />
                    <div className="owl-carousel-inner">
                        <div className="container">
                            <div className="row justify-content-start">
                                <div className="col-10 col-lg-8">
                                    <h1 className="display-2 text-white animated slideInDown">Solar Water Heating</h1>
                                    <p className="fs-4 fw-medium text-white mb-4 pb-3">Reduce your Electricity Bill with Solar Water Heaters</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="owl-carousel-item position-relative">
                    <img className="img-fluid" src="img/residentialrooftop.jpg" alt="" />
                    <div className="owl-carousel-inner">
                        <div className="container">
                            <div className="row justify-content-start">
                                <div className="col-10 col-lg-8">
                                    <h1 className="display-2 text-white animated slideInDown">Rooftop/Shed Solars</h1>
                                    <p className="fs-4 fw-medium text-white mb-4 pb-3">Reduce your Electricity Bill with Rooftop Solars</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    );
}

export default Carouselresidential;