import React from 'react';
import Carouselhome from './Components/Carouselhome';
import Counts from './Components/Counts';
import About from './Components/About';
import Service from './Components/Service';
import Ongrid from './Components/ongrid';
import Offgrid from './Components/offgrid';
import Hybrid from './Components/hybrid';
import Products from './Components/Products';
/* import Quote from '../Components/Quote';
import Team from '../Components/Team'; */
import Clients from './Components/Clients';
/* import Testimonial from './Components/Testimonial'; */
import Faq from './Components/faq';
import Whyus from './Components/Whyus';

function Home() {
  return (
    <div>
            <Carouselhome />
            <Counts />
            <About />
            <Service />
            <Ongrid />
            <Offgrid />
            <Hybrid />
            <Products />
            <Clients />
            {/* <Quote /> */}
            {/* <Team /> */}
            <Whyus />
            <Faq />
            {/* Other components and content */}
    </div>
  );
}

export default Home;