/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'; 

const Whyus = () => {
    return (
        <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0" id="about-section">
            <div className="container whyus px-lg-0">
                <div className="row g-0 mx-lg-0">
                    <div className="col-lg-6 ps-lg-0 wow fadeIn" data-wow-delay="0.1s" style={{ minHeight: "400px" }}>
                        <div className="position-relative h-100">
                            <img className="position-absolute img-fluid w-100 h-100" src="img/whyus.jpg" style={{ objectFit: "cover" }} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 whyus-text py-5 wow fadeIn" data-wow-delay="0.5s">
                        <div className="p-lg-5 pe-lg-0">
                            <h6 className="text-primary">Why Choose Us</h6>
                            <h1 className="mb-4">Here is why you should choose Yo-Green</h1>
                            <i className="fa fa-check-circle text-primary me-3" style={{float: 'left', lineHeight: 1.6}}></i><p>Collateral-free solar financing for commercial and industrial customers</p>
                            <i className="fa fa-check-circle text-primary me-3" style={{float: 'left', lineHeight: 1.6}}></i><p>World-class solar panels manufactured in-house</p>
                            <i className="fa fa-check-circle text-primary me-3" style={{float: 'left', lineHeight: 1.6}}></i><p>Free site survey, system design and financial analysis supporting your solar investment</p>
                            <i className="fa fa-check-circle text-primary me-3" style={{float: 'left', lineHeight: 1.6}}></i><p>Quick and hassle-free installation</p>
                            <i className="fa fa-check-circle text-primary me-3" style={{float: 'left', lineHeight: 1.6}}></i><p>25-year power warranty on solar panels</p>
                            <i className="fa fa-check-circle text-primary me-3" style={{float: 'left', lineHeight: 1.6}}></i><p>Three free after-sales service visits in first year</p>
                            <i className="fa fa-check-circle text-primary me-3" style={{float: 'left', lineHeight: 1.6}}></i><p>Paid after-sales service available from second year</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Whyus;
