/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const Products = () => {
    return (
        <div className="container-xxl py-5" id="products-section">
            <div className="container">
                <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '600px' }}>
                    <h6 className="text-primary">Our Products</h6>
                    <h1 className="mb-4">Visit Our Latest Solar And Renewable Energy Products</h1>
                </div>
                <div className="row g-4 portfolio-container wow fadeInUp" data-wow-delay="0.5s">
                    <div className="col-lg-4 col-md-6 portfolio-item">
                        <div className="portfolio-img rounded overflow-hidden">
                            <img className="img-fluid" src="img/img-600x400-6.jpg" alt="" />
                            <div className="portfolio-btn">
                                <a className="btn btn-lg-square btn-outline-light rounded-circle mx-1" href="img/img-600x400-6.jpg" data-lightbox="portfolio"><i className="fa fa-eye"></i></a>
                                <a className="btn btn-lg-square btn-outline-light rounded-circle mx-1" href=""><i className="fa fa-link"></i></a>
                            </div>
                        </div>
                        <div className="pt-3">
                            <p className="text-primary mb-0">Solar Water Heater</p>
                            <hr className="text-primary w-25 my-2" />
                            <h5 className="lh-base">We Are pioneers of solar & renewable energy industry</h5>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 portfolio-item">
                        <div className="portfolio-img rounded overflow-hidden">
                            <img className="img-fluid" src="img/img-600x400-5.jpg" alt="" />
                            <div className="portfolio-btn">
                                <a className="btn btn-lg-square btn-outline-light rounded-circle mx-1" href="img/img-600x400-5.jpg" data-lightbox="portfolio"><i className="fa fa-eye"></i></a>
                                <a className="btn btn-lg-square btn-outline-light rounded-circle mx-1" href=""><i className="fa fa-link"></i></a>
                            </div>
                        </div>
                        <div className="pt-3">
                            <p className="text-primary mb-0">Solar Street Light</p>
                            <hr className="text-primary w-25 my-2" />
                            <h5 className="lh-base">We Are pioneers of solar & renewable energy industry</h5>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 portfolio-item">
                        <div className="portfolio-img rounded overflow-hidden">
                            <img className="img-fluid" src="img/img-600x400-4.jpg" alt="" />
                            <div className="portfolio-btn">
                                <a className="btn btn-lg-square btn-outline-light rounded-circle mx-1" href="img/img-600x400-4.jpg" data-lightbox="portfolio"><i className="fa fa-eye"></i></a>
                                <a className="btn btn-lg-square btn-outline-light rounded-circle mx-1" href=""><i className="fa fa-link"></i></a>
                            </div>
                        </div>
                        <div className="pt-3">
                            <p className="text-primary mb-0">Solar Water Pump</p>
                            <hr className="text-primary w-25 my-2" />
                            <h5 className="lh-base">We Are pioneers of solar & renewable energy industry</h5>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 portfolio-item">
                    <div className="portfolio-img rounded overflow-hidden">
                        <img className="img-fluid" src="img/img-600x400-3.jpg" alt="" />
                        <div className="portfolio-btn">
                            <a className="btn btn-lg-square btn-outline-light rounded-circle mx-1" href="img/img-600x400-3.jpg" data-lightbox="portfolio"><i className="fa fa-eye"></i></a>
                            <a className="btn btn-lg-square btn-outline-light rounded-circle mx-1" href=""><i className="fa fa-link"></i></a>
                        </div>
                    </div>
                    <div className="pt-3">
                        <p className="text-primary mb-0">Solar Small Combo Unit</p>
                        <hr className="text-primary w-25 my-2" />
                        <h5 className="lh-base">We Are pioneers of solar & renewable energy industry</h5>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 portfolio-item">
                    <div className="portfolio-img rounded overflow-hidden">
                        <img className="img-fluid" src="img/img-600x400-2.jpg" alt="" />
                        <div className="portfolio-btn">
                            <a className="btn btn-lg-square btn-outline-light rounded-circle mx-1" href="img/img-600x400-2.jpg" data-lightbox="portfolio"><i className="fa fa-eye"></i></a>
                            <a className="btn btn-lg-square btn-outline-light rounded-circle mx-1" href=""><i className="fa fa-link"></i></a>
                        </div>
                    </div>
                    <div className="pt-3">
                        <p className="text-primary mb-0">Most Efficient Inverter And Battery Available</p>
                        <hr className="text-primary w-25 my-2" />
                        <h5 className="lh-base">We Are pioneers of solar & renewable energy industry</h5>
                    </div>
                </div>
                {/* <div className="col-lg-4 col-md-6 portfolio-item">
                    <div className="portfolio-img rounded overflow-hidden">
                        <img className="img-fluid" src="img/img-600x400-1.jpg" alt="" />
                        <div className="portfolio-btn">
                            <a className="btn btn-lg-square btn-outline-light rounded-circle mx-1" href="img/img-600x400-1.jpg" data-lightbox="portfolio"><i className="fa fa-eye"></i></a>
                            <a className="btn btn-lg-square btn-outline-light rounded-circle mx-1" href=""><i className="fa fa-link"></i></a>
                        </div>
                    </div>
                    <div className="pt-3">
                        <p className="text-primary mb-0">Hydropower Plants</p>
                        <hr className="text-primary w-25 my-2" />
                        <h5 className="lh-base">We Are pioneers of solar & renewable energy industry</h5>
                    </div>
                </div> */}
                </div>
            </div>
        </div>
    );
}

export default Products;