import React, { useEffect } from 'react';
import Carouselresidential from './Components/Carouselresidential';
import BenefitsRooftop from './Components/Benefitsrooftop';
/* import Ongrid from './Components/ongrid';
import Offgrid from './Components/offgrid';
import Hybrid from './Components/hybrid'; */

function Residential() {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
}, []);

  return (
    <div>
            <Carouselresidential />
            <BenefitsRooftop />
            {/* <Ongrid />
            <Offgrid />
            <Hybrid /> */}
    </div>
  );
}

export default Residential;