import React from 'react';

const ClientsSection = () => {
  return (
    <section id="clients" className="clients clients">
      <div className="clients-container">
        <div className="row">
          <div className="col-lg-2 col-md-3 col-3">
            <img src={`img/clients/client-1.png`} className="img-fluid" alt="" data-aos="zoom-in" />
          </div>
          <div className="col-lg-2 col-md-3 col-3">
            <img src={`img/clients/client-2.png`} className="img-fluid" alt="" data-aos="zoom-in" data-aos-delay="100" />
          </div>
          <div className="col-lg-2 col-md-3 col-3">
            <img src={`img/clients/client-3.png`} className="img-fluid" alt="" data-aos="zoom-in" data-aos-delay="200" />
          </div>
          <div className="col-lg-2 col-md-3 col-3">
            <img src={`img/clients/client-4.png`} className="img-fluid" alt="" data-aos="zoom-in" data-aos-delay="300" />
          </div>
          <div className="col-lg-2 col-md-3 col-3">
            <img src={`img/clients/client-5.png`} className="img-fluid" alt="" data-aos="zoom-in" data-aos-delay="400" />
          </div>
          <div className="col-lg-2 col-md-3 col-3">
            <img src={`img/clients/client-6.png`} className="img-fluid" alt="" data-aos="zoom-in" data-aos-delay="500" />
          </div>
          <div className="col-lg-2 col-md-3 col-3">
            <img src={`img/clients/client-7.png`} className="img-fluid" alt="" data-aos="zoom-in" data-aos-delay="500" />
          </div>
          <div className="col-lg-2 col-md-3 col-3">
            <img src={`img/clients/client-8.png`} className="img-fluid" alt="" data-aos="zoom-in" data-aos-delay="500" />
          </div>
          <div className="col-lg-2 col-md-3 col-3">
            <img src={`img/clients/client-9.png`} className="img-fluid" alt="" data-aos="zoom-in" data-aos-delay="500" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default ClientsSection;