import React, { useEffect } from 'react';
import Hybrid from './Components/hybrid';
import Products from './Components/Products';

function Aboutus() {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
}, []);

  return (
    <div>
            <Hybrid />
            <Products />
    </div>
  );
}

export default Aboutus;