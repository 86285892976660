import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Home from './Home';
import Residential from './Residential';
import Aboutus from './Aboutus';
import Commercial from './Commercial';
import Industrial from './Industrial';
import Government from './Government';
import Admin from './Admin';
import Footer from './Components/Footer';

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Residential" element={<Residential />} />
          <Route path="/Commercial" element={<Commercial />} />
          <Route path="/Industrial" element={<Industrial />} />
          <Route path="/Government" element={<Government />} />
          <Route path="/Aboutus" element={<Aboutus />} />
          <Route path="/@dm!^" element={<Admin />} />
          {/* Add more routes as needed */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
