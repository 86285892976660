import React from 'react';

const Governmentschemes = () => {
  return (
    <div className="mb-8" id="faq-section">
      <div className="container border-top">
        <div className="row">
          <div className="col-12">
            <div className="d-flex align-items-end mb-5">
              <i className="bi bi-person-gear me-3 lh-1 display-5"></i>
              <h1 className="mx-auto text-center pt-5">Government Schemes</h1>
            </div>
          </div>
          <div className="col-12">
            <div className="accordion accordion-flush" id="faqAccount">
              <div className="row">
                <div className="col-md-6">
                  <div className="accordion-item bg-transparent border-top border-bottom py-3">
                    <h2 className="accordion-header" id="faqAccountHeading19">
                      <button className="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse19" aria-expanded="false" aria-controls="faqAccountCollapse19">
                      Grid Connected
                      </button>
                    </h2>
                    <div id="faqAccountCollapse19" className="accordion-collapse collapse" aria-labelledby="faqAccountHeading19">
                      <div className="accordion-body">
                      <p>Click The Below Links To Know More</p>
                        <ul>
                            <li className='pt-2 pb-2'>
                                <a href="https://mnre.gov.in/central-public-sector-undertaking-cpsu-scheme-phase-ii-government-producer-scheme-for-setting-up-12000-mw-grid-connected-solar-photovoltaic-pv-power-projects-by-the-government-producers-with-vi/" target='_blank' rel="noreferrer">CPSU Scheme Phase-II (Government Producer Scheme)</a>
                            </li>
                            <li className='pt-2 pb-2'>
                                <a href="https://mnre.gov.in/grid-connected-solar-rooftop-programme/" target='_blank' rel="noreferrer">Grid Connected Rooftop Solar Programme</a>
                            </li>
                            <li className='pt-2 pb-2'>
                                <a href="https://mnre.gov.in/development-of-solar-parks-and-ultra-mega-solar-power-projects/" target='_blank' rel="noreferrer">Development of Solar Parks and Ultra Mega Solar Power Projects</a>
                            </li>
                            <li className='pt-2 pb-2'>
                                <a href="https://mnre.gov.in/solarisation-of-sun-temple-town-of-modhera-in-mehsana-district-gujarat/" target='_blank' rel="noreferrer">Solarisation of Sun Temple Town of Modhera in Mehsana District, Gujarat</a>
                            </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="accordion-item bg-transparent border-top border-bottom py-3">
                    <h2 className="accordion-header" id="faqAccountHeading20">
                      <button className="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse20" aria-expanded="false" aria-controls="faqAccountCollapse20">
                      Solar Off-Grid
                      </button>
                    </h2>
                    <div id="faqAccountCollapse20" className="accordion-collapse collapse" aria-labelledby="faqAccountHeading20">
                      <div className="accordion-body">
                      <p>Click The Below Links To Know More</p>
                      <ul>
                            <li className='pt-2 pb-2'>
                                <a href="https://mnre.gov.in/pradhan-mantri-kisan-urja-suraksha-evam-utthaan-mahabhiyaan-pm-kusum/" target='_blank' rel="noreferrer">Pradhan Mantri Kisan Urja Suraksha evam Utthaan Mahabhiyaan (PM KUSUM)</a>
                            </li>
                            <li className='pt-2 pb-2'>
                                <a href="https://mnre.gov.in/pm-janman/" target='_blank' rel="noreferrer">New Solar Power Scheme (for PVTG Habitations/Villages) under PM JANMAN</a>
                            </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Governmentschemes;