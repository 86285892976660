import React from 'react';

const Faq = () => {
  return (
    <div className="mb-8" id="faq-section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="d-flex align-items-end mb-5">
              <i className="bi bi-person-gear me-3 lh-1 display-5"></i>
              <h1 className="mx-auto text-center">F.A.Q</h1>
            </div>
          </div>
          <div className="col-12">
            <div className="accordion accordion-flush" id="faqAccount">
              <div className="row">
                <div className="col-md-6">
                  <div className="accordion-item bg-transparent border-top border-bottom py-3">
                    <h2 className="accordion-header" id="faqAccountHeading1">
                      <button className="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse1" aria-expanded="false" aria-controls="faqAccountCollapse1">
                        What is a solar rooftop solution, and how does it work?
                      </button>
                    </h2>
                    <div id="faqAccountCollapse1" className="accordion-collapse collapse" aria-labelledby="faqAccountHeading1">
                      <div className="accordion-body">
                        <p>A solar rooftop solution refers to the installation of solar panels on the roof of a building to harness sunlight and convert it into electricity. Solar panels consist of photovoltaic cells that capture sunlight and generate direct current (DC) electricity, which is then converted into alternating current (AC) electricity by an inverter. This electricity can be used to power your home's appliances and lighting.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="accordion-item bg-transparent border-top border-bottom py-3">
                    <h2 className="accordion-header" id="faqAccountHeading2">
                      <button className="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse2" aria-expanded="false" aria-controls="faqAccountCollapse2">
                      What are the key components of a solar rooftop system?
                      </button>
                    </h2>
                    <div id="faqAccountCollapse2" className="accordion-collapse collapse" aria-labelledby="faqAccountHeading2">
                      <div className="accordion-body">
                        <p>The main components of a solar rooftop system include solar panels, inverters, mounting racks, wiring, and sometimes a battery storage system for off-grid or hybrid setups.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="accordion-item bg-transparent border-top border-bottom py-3">
                    <h2 className="accordion-header" id="faqAccountHeading3">
                      <button className="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse3" aria-expanded="false" aria-controls="faqAccountCollapse3">
                      What is the difference between on-grid, off-grid, and hybrid solar systems?
                      </button>
                    </h2>
                    <div id="faqAccountCollapse3" className="accordion-collapse collapse" aria-labelledby="faqAccountHeading3">
                      <div className="accordion-body">
                        <p>On-grid systems are connected to the utility grid and do not typically require battery storage. Off-grid systems operate independently of the grid and rely on batteries for energy storage. Hybrid systems combine elements of both on-grid and off-grid systems, allowing homeowners to use solar energy while still having a connection to the grid for backup power.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="accordion-item bg-transparent border-top border-bottom py-3">
                    <h2 className="accordion-header" id="faqAccountHeading4">
                      <button className="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse4" aria-expanded="false" aria-controls="faqAccountCollapse4">
                      How do I know if my home is suitable for solar panels?
                      </button>
                    </h2>
                    <div id="faqAccountCollapse4" className="accordion-collapse collapse" aria-labelledby="faqAccountHeading4">
                      <div className="accordion-body">
                        <p>Homes with unobstructed access to sunlight throughout the day are generally suitable for solar panels. Factors such as roof orientation, tilt, shading, and available roof space should be considered.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="accordion-item bg-transparent border-top border-bottom py-3">
                    <h2 className="accordion-header" id="faqAccountHeading5">
                      <button className="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse5" aria-expanded="false" aria-controls="faqAccountCollapse5">
                      What are the environmental benefits of installing a solar rooftop system?
                      </button>
                    </h2>
                    <div id="faqAccountCollapse5" className="accordion-collapse collapse" aria-labelledby="faqAccountHeading5">
                      <div className="accordion-body">
                        <p>Installing a solar rooftop system reduces reliance on fossil fuels, decreases greenhouse gas emissions, and helps combat climate change. Solar energy is clean, renewable, and sustainable.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="accordion-item bg-transparent border-top border-bottom py-3">
                    <h2 className="accordion-header" id="faqAccountHeading6">
                      <button className="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse6" aria-expanded="false" aria-controls="faqAccountCollapse6">
                      How much money can I save by switching to solar energy?
                      </button>
                    </h2>
                    <div id="faqAccountCollapse6" className="accordion-collapse collapse" aria-labelledby="faqAccountHeading6">
                      <div className="accordion-body">
                        <p>The amount of money saved depends on factors such as your energy consumption, local electricity rates, available incentives, and the size and efficiency of your solar system. On average, homeowners can save thousands of dollars over the lifetime of their solar panels.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="accordion-item bg-transparent border-top border-bottom py-3">
                    <h2 className="accordion-header" id="faqAccountHeading7">
                      <button className="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse7" aria-expanded="false" aria-controls="faqAccountCollapse7">
                      Are there any government incentives or rebates available for installing solar panels?
                      </button>
                    </h2>
                    <div id="faqAccountCollapse7" className="accordion-collapse collapse" aria-labelledby="faqAccountHeading7">
                      <div className="accordion-body">
                        <p>Many governments offer incentives such as tax credits, rebates, and feed-in tariffs to encourage the adoption of solar energy. Check with your local government or utility provider to see what incentives are available in your area.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="accordion-item bg-transparent border-top border-bottom py-3">
                    <h2 className="accordion-header" id="faqAccountHeading8">
                      <button className="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse8" aria-expanded="false" aria-controls="faqAccountCollapse8">
                      How long does it take to recoup the initial investment in a solar rooftop system?
                      </button>
                    </h2>
                    <div id="faqAccountCollapse8" className="accordion-collapse collapse" aria-labelledby="faqAccountHeading8">
                      <div className="accordion-body">
                        <p>The payback period for a solar rooftop system varies depending on factors such as installation costs, energy savings, and available incentives. On average, homeowners can expect to recoup their initial investment within 5 to 10 years.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="accordion-item bg-transparent border-top border-bottom py-3">
                    <h2 className="accordion-header" id="faqAccountHeading9">
                      <button className="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse9" aria-expanded="false" aria-controls="faqAccountCollapse9">
                      Can I sell excess electricity generated by my solar panels back to the grid?
                      </button>
                    </h2>
                    <div id="faqAccountCollapse9" className="accordion-collapse collapse" aria-labelledby="faqAccountHeading9">
                      <div className="accordion-body">
                        <p>In many jurisdictions, homeowners with on-grid solar systems can participate in net metering programs, allowing them to sell excess electricity back to the grid for credits on their utility bills.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="accordion-item bg-transparent border-top border-bottom py-3">
                    <h2 className="accordion-header" id="faqAccountHeading10">
                      <button className="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse10" aria-expanded="false" aria-controls="faqAccountCollapse10">
                      What maintenance is required for a solar rooftop system?
                      </button>
                    </h2>
                    <div id="faqAccountCollapse10" className="accordion-collapse collapse" aria-labelledby="faqAccountHeading10">
                      <div className="accordion-body">
                        <p>Solar panels require minimal maintenance, typically limited to occasional cleaning to remove dust and debris. Inverters may require regular inspections, and battery systems (if installed) may need periodic maintenance.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="accordion-item bg-transparent border-top border-bottom py-3">
                    <h2 className="accordion-header" id="faqAccountHeading11">
                      <button className="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse11" aria-expanded="false" aria-controls="faqAccountCollapse11">
                      How long do solar panels typically last?
                      </button>
                    </h2>
                    <div id="faqAccountCollapse11" className="accordion-collapse collapse" aria-labelledby="faqAccountHeading11">
                      <div className="accordion-body">
                        <p>Most solar panels come with warranties ranging from 20 to 25 years, but they can last much longer with proper care. Over time, solar panels may experience a slight decrease in efficiency, known as degradation.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="accordion-item bg-transparent border-top border-bottom py-3">
                    <h2 className="accordion-header" id="faqAccountHeading12">
                      <button className="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse12" aria-expanded="false" aria-controls="faqAccountCollapse12">
                      Will installing solar panels increase the value of my home?
                      </button>
                    </h2>
                    <div id="faqAccountCollapse12" className="accordion-collapse collapse" aria-labelledby="faqAccountHeading12">
                      <div className="accordion-body">
                        <p>Studies have shown that homes with solar panels tend to sell for more than those without. Solar panels can increase the value of your home by reducing energy costs and providing a source of renewable energy.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="accordion-item bg-transparent border-top border-bottom py-3">
                    <h2 className="accordion-header" id="faqAccountHeading13">
                      <button className="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse13" aria-expanded="false" aria-controls="faqAccountCollapse13">
                      What happens to my solar power production during cloudy days or at night?
                      </button>
                    </h2>
                    <div id="faqAccountCollapse13" className="accordion-collapse collapse" aria-labelledby="faqAccountHeading13">
                      <div className="accordion-body">
                        <p>Solar panels still generate electricity on cloudy days, although at a reduced rate. At night, when there is no sunlight, your home will draw electricity from the grid or battery storage system if one is installed.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="accordion-item bg-transparent border-top border-bottom py-3">
                    <h2 className="accordion-header" id="faqAccountHeading14">
                      <button className="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse14" aria-expanded="false" aria-controls="faqAccountCollapse14">
                      Can I install a battery backup system with my solar panels?
                      </button>
                    </h2>
                    <div id="faqAccountCollapse14" className="accordion-collapse collapse" aria-labelledby="faqAccountHeading14">
                      <div className="accordion-body">
                        <p>Yes, homeowners can install battery backup systems to store excess solar energy for use during power outages or times of high energy demand. Battery systems provide added resilience and independence from the grid.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="accordion-item bg-transparent border-top border-bottom py-3">
                    <h2 className="accordion-header" id="faqAccountHeading15">
                      <button className="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse15" aria-expanded="false" aria-controls="faqAccountCollapse15">
                      What factors should I consider when choosing a solar panel installer?
                      </button>
                    </h2>
                    <div id="faqAccountCollapse15" className="accordion-collapse collapse" aria-labelledby="faqAccountHeading15">
                      <div className="accordion-body">
                        <p>When choosing a solar panel installer, consider factors such as experience, reputation, warranties offered, customer reviews, and pricing. It's essential to work with a reputable and qualified installer to ensure a successful installation.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="accordion-item bg-transparent border-top border-bottom py-3">
                    <h2 className="accordion-header" id="faqAccountHeading16">
                      <button className="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse16" aria-expanded="false" aria-controls="faqAccountCollapse16">
                      How do I calculate the size of the solar system needed for my home?
                      </button>
                    </h2>
                    <div id="faqAccountCollapse16" className="accordion-collapse collapse" aria-labelledby="faqAccountHeading16">
                      <div className="accordion-body">
                        <p>The size of your solar system depends on factors such as your energy consumption, roof size, available sunlight, and desired level of energy independence. A solar installer can conduct a site assessment and recommend the appropriate system size for your needs.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="accordion-item bg-transparent border-top border-bottom py-3">
                    <h2 className="accordion-header" id="faqAccountHeading17">
                      <button className="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse17" aria-expanded="false" aria-controls="faqAccountCollapse17">
                      Are there any zoning or permit requirements for installing solar panels on my roof?
                      </button>
                    </h2>
                    <div id="faqAccountCollapse17" className="accordion-collapse collapse" aria-labelledby="faqAccountHeading17">
                      <div className="accordion-body">
                        <p>Yes, there may be zoning regulations and permit requirements for installing solar panels, depending on your location. It's essential to check with your local government or building department to ensure compliance with regulations.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="accordion-item bg-transparent border-top border-bottom py-3">
                    <h2 className="accordion-header" id="faqAccountHeading18">
                      <button className="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse18" aria-expanded="false" aria-controls="faqAccountCollapse18">
                      How does shading affect the performance of solar panels?
                      </button>
                    </h2>
                    <div id="faqAccountCollapse18" className="accordion-collapse collapse" aria-labelledby="faqAccountHeading18">
                      <div className="accordion-body">
                        <p>Shading from trees, buildings, or other obstructions can reduce the performance of solar panels by blocking sunlight. It's essential to minimize shading as much as possible to maximize the efficiency of your solar system.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="accordion-item bg-transparent border-top border-bottom py-3">
                    <h2 className="accordion-header" id="faqAccountHeading19">
                      <button className="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse19" aria-expanded="false" aria-controls="faqAccountCollapse19">
                      What warranties are typically included with solar panel installations?
                      </button>
                    </h2>
                    <div id="faqAccountCollapse19" className="accordion-collapse collapse" aria-labelledby="faqAccountHeading19">
                      <div className="accordion-body">
                        <p>Solar panels typically come with performance warranties ranging from 20 to 25 years, guaranteeing a certain level of electricity production over the lifespan of the panels. Inverters and other components may also come with warranties.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="accordion-item bg-transparent border-top border-bottom py-3">
                    <h2 className="accordion-header" id="faqAccountHeading20">
                      <button className="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse20" aria-expanded="false" aria-controls="faqAccountCollapse20">
                      Can I monitor the performance of my solar panels remotely?
                      </button>
                    </h2>
                    <div id="faqAccountCollapse20" className="accordion-collapse collapse" aria-labelledby="faqAccountHeading20">
                      <div className="accordion-body">
                        <p>Many solar panel systems come with monitoring software that allows homeowners to track the performance of their solar panels remotely. Monitoring software provides real-time data on energy production, allowing homeowners to optimize their system's performance.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="accordion-item bg-transparent border-top border-bottom py-3">
                    <h2 className="accordion-header" id="faqAccountHeading21">
                      <button className="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse21" aria-expanded="false" aria-controls="faqAccountCollapse21">
                      What are the key tax benefits of installing solar panels in India?
                      </button>
                    </h2>
                    <div id="faqAccountCollapse21" className="accordion-collapse collapse" aria-labelledby="faqAccountHeading21">
                      <div className="accordion-body">
                        <p>India offers several tax benefits that make installing solar panels financially attractive for both businesses and homeowners. One of the primary incentives is Accelerated Depreciation, which allows businesses to depreciate 40% of the solar system's value in the first year, significantly reducing taxable income and enhancing the project’s return on investment (ROI). For example, a company investing INR 1 crore in solar can save INR 12 lakhs in taxes in the first year alone if it's in the 30% tax bracket.

Another major benefit is the Tax Holiday under Section 80-IA of the Income Tax Act, which offers a 100% tax exemption on profits generated from solar projects for any ten consecutive years within the first fifteen years of operation. This provision is particularly beneficial for large-scale solar projects, making them more financially viable by allowing businesses to reinvest their profits rather than paying taxes during the critical early years of operation.

In addition, Goods and Services Tax (GST) Benefits also apply, with a lower GST rate of 5% on solar power generating systems, reducing the initial cost of installation.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="accordion-item bg-transparent border-top border-bottom py-3">
                    <h2 className="accordion-header" id="faqAccountHeading22">
                      <button className="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse22" aria-expanded="false" aria-controls="faqAccountCollapse22">
                      How do capital subsidies and net metering enhance the financial viability of solar installations in India?
                      </button>
                    </h2>
                    <div id="faqAccountCollapse22" className="accordion-collapse collapse" aria-labelledby="faqAccountHeading22">
                      <div className="accordion-body">
                        <p>Capital Subsidies offered by the Ministry of New and Renewable Energy (MNRE) can cover up to 30% of the cost of residential rooftop solar installations, varying by state and project size. For example, a 30% subsidy on a system costing INR 2 lakhs reduces the upfront cost to INR 1.4 lakhs, making solar energy more accessible to a broader population. In special category states like Himachal Pradesh and Jammu & Kashmir, subsidies can be as high as 70%.

Net Metering allows solar power system owners to sell excess electricity back to the grid, providing an additional revenue stream. For instance, if a household generates more electricity than it consumes, the excess energy is credited to their account, lowering future electricity bills. Over time, this can significantly enhance the return on investment, ensuring that the solar power system not only pays for itself but also generates long-term financial benefits.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;